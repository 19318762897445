<template>
  <div>
    <Modal class="attend-show-complete" footer-hide v-model="show">
      <div class="top">
        <div class="item item-top-info">
          <div class="item-logo">
            <img :src="editInquiryForm.logo.url" alt="" v-if="editInquiryForm.logo"/>
            <img src="https://connexima.s3-us-west-1.amazonaws.com/documents/vxBpNJ7ctQgsU5xw8cH3GY.png" alt="" v-else/>
          </div>
          <div class="item-company_name">
            <div class="company-name">{{editInquiryForm.company_name}}</div>
            <div class="company-country">{{editInquiryForm.country_name.name}}</div>
          </div>
        </div>
      </div>
      <div class="tabs">
        <div class="tab-item" :class="{'tab-item-active':currentTab == 1}" @click="chooseTab(1)">Company</div>
        <div class="tab-item " :class="{'tab-item-active':currentTab == 2}" @click="chooseTab(2)">Attendee</div>
        <div class="tab-item" :class="{'tab-item-active':currentTab == 3}" @click="chooseTab(3)">Show</div>
      </div>
      <div class="content">
        <template v-if="currentTab == 1">

          
          <div class="item">
            <div class="input">
              <div class="label">
                Address
              </div>
              <div class="value ">
                {{editInquiryForm.address}}
              </div>
            </div>
            <div class="input">
              <div class="label">
                Company Type
              </div>
              <div class="value">
<!--                <v-select :options="companyType" label="Company Type" multiple v-model="editInquiryForm.company_type" disabled>-->

<!--                </v-select>-->
                <div class="value  ">
                  {{editInquiryForm.company_type}}
<!--                  <input type="text" v-model="editInquiryForm.company_type" placeholder="" disabled/>-->
                </div>

              </div>
            </div>
            <div class="input">
              <div class="label">
                Service
              </div>
              <div class="value ">
                <div class="value  ">
                  {{editInquiryForm.service}}
                </div>

              </div>
            </div>
          </div>
          <div class="item">
            <div class="input">
              <div class="label">
                Industry
              </div>
              <div class="value">
                {{editInquiryForm.industry ? editInquiryForm.industry : '---'}}

              </div>
            </div>
            <div class="input">
              <div class="label">
                Application
              </div>
              <div class="value value-c">
                {{editInquiryForm.application ? editInquiryForm.application : '---'}}
              </div>
            </div>
            <div class="input">
              <div class="label">
                Purpose
              </div>
              <div class="value ">
                {{editInquiryForm.purpose ? editInquiryForm.purpose : '---' }}
              </div>
            </div>
          </div>
          <div class="item">
            <div class="input">
              <div class="label">
                Annual Sales Revenue(USD)
              </div>
              <div class="value  ">
                {{editInquiryForm.sales_revenue ? editInquiryForm.sales_revenue : '---'}}
              </div>
            </div>
            <div class="input">
              <div class="label">
                Number of Employees
              </div>
              <div class="value ">
                {{editInquiryForm.employees ? editInquiryForm.employees : '---'}}

              </div>
            </div>
            <div class="input">
              <div class="label">
                Year Established
              </div>
              <div class="value  ">
                {{editInquiryForm.year_established ? editInquiryForm.year_established : '---'}}
              </div>
            </div>
          </div>

          <div class="item">
            <div class="input">
              <div class="label">
                Category
              </div>
              <div class="value">
                {{editInquiryForm.category ? editInquiryForm.category : '---'}}
              </div>
            </div>
            <div class="input">
              <div class="label">
                Business Territory
              </div>
              <div class="value ">
                {{editInquiryForm.business_territory ? editInquiryForm.business_territory : '---'}}
              </div>
            </div>


          </div>

          <div class="item" v-if="editInquiryForm.product_list">
            <div class="input">
              <div class="label">
                Products/Services
              </div>
              <div class="value  ">
                {{editInquiryForm.product_list}}
                <!--              <input type="text" v-model="editInquiryForm.product_list" />-->
              </div>
            </div>

          </div>
          <div class="item" v-if="editInquiryForm.company_profile">
            <div class="input">
              <div class="label">
                Company Profile
              </div>
              <div class="value value-c">
                {{editInquiryForm.company_profile}}
              </div>
            </div>
          </div>
        </template>
        <template v-if="currentTab == 2">
          <div class="item">
            <div class="input">
              <div class="label">
                Attendee Name
              </div>
              <div class="value ">
                {{editInquiryForm.attendee.name}}

              </div>
            </div>
            <div class="input">
              <div class="label">
                Title
              </div>
              <div class="value ">
                {{editInquiryForm.attendee.title ? editInquiryForm.attendee.title : '---'}}
              </div>
            </div>
            <div class="input">
              <div class="label">
                Tel
              </div>
              <div class="value ">
                {{editInquiryForm.attendee.tel ? editInquiryForm.attendee.tel : '---'}}

              </div>
            </div>
          </div>
          <div class="item">
            <div class="input">
              <div class="label">
                Facebook
              </div>
              <div class="value ">
                {{editInquiryForm.attendee.facebook ? editInquiryForm.attendee.facebook : '---'}}

              </div>
            </div>
            <div class="input">
              <div class="label">
                Linkedin
              </div>
              <div class="value ">
                {{editInquiryForm.attendee.linkedin ? editInquiryForm.attendee.linkedin : '---'}}
              </div>
            </div>
            <div class="input">
              <div class="label">
                WhatsApp
              </div>
              <div class="value ">
                {{editInquiryForm.attendee.whatsApp ? editInquiryForm.attendee.whatsApp : '---'}}

              </div>
            </div>
          </div>

          <div class="item" style="justify-content: start">
            <div class="input" v-if="editInquiryForm.attendee.avatar">
              <div class="label">
                Avatar
              </div>
              <div class="value search-button file-value">
                 <span>
                   <a :href="editInquiryForm.attendee.avatar" target="_blank">
                                       <img :src="editInquiryForm.attendee.avatar ? editInquiryForm.attendee.avatar.url : ''" alt=""/>
                   </a>
                </span>
              </div>
            </div>
            <div class="input" v-if="editInquiryForm.attendee.business_card">
              <div class="label">
                Business Card
              </div>
              <div class="value search-button file-value">
                 <span>
                  <a :href="editInquiryForm.attendee.business_card ? editInquiryForm.attendee.business_card.url : ''" target="_blank">
                                       <img :src="editInquiryForm.attendee.business_card ? editInquiryForm.attendee.business_card.url : ''" alt=""/>
                   </a>
                 </span>
              </div>
            </div>
          </div>
        </template>

        <template v-if="currentTab == 3">
          <template v-if="!contactsLoading ">
            <div class="content-item d-flex" v-for="(item,index) in shows" :key="index">
              <div class="left">
                <div class="name">
                  {{item.name}}
                </div>
                <!--                    <div class="email" @click="copy(item.value)">{{item.value}}</div>-->
              </div>
              <div class="middle d-flex">
                {{item.address}}
              </div>
              <div class="right d-flex">

                <div class="right-item right-item-phone"   >
                  {{item.date_str}}
                </div>

              </div>

            </div>

          </template>
          <template v-else-if="contactsLoading">
            <div class="contacts-loading">
              <img src="@/assets/loading.gif" />
            </div>
          </template>
          <template v-else>
            <div class="contacts-loading">
              No Trade Shows
            </div>
          </template>
          <div class="contacts-page">
            <div class="prev" >
              <img src="@/assets/contact/prev.png" v-if="pageNum > 1" @click="prevPage()"/>
              <img src="@/assets/contact/prev-2.png" v-else/>
            </div>
            <div class="next">
              <img src="@/assets/contact/next.png" @click="nextPage()" v-if="page.last_page > pageNum"/>
              <img src="@/assets/contact/next-2.png" v-else/>
            </div>
          </div>
        </template>



      </div>

      <div class="btn">
        <template v-if=" canContact">

          <div class="save-btn search-button" @click="next" v-if="!contacting">
            Contact
          </div>
          <div class="save-btn search-button contacting" v-else>
            Contacting
          </div>

        </template>
        <template v-else>
          <div class="save-btn search-button not-contact" >
            Contact
          </div>
        </template>
      </div>
    </Modal>
    <SuccessContact ref="successContact"></SuccessContact>
  </div>
</template>

<script>
import api from "@/api";

const { _editSourcing, _country,_AttendTradeShow,_getSingleCompanyShows,_directContactTradeShowCompany } = api;
import { mapState } from "vuex";
import S3 from "aws-s3";
import SuccessContact from "@/components/successContact";

const uploader = new S3({
  bucketName: "connexima",
  dirName: "documents",
  region: "us-west-1",
  accessKeyId: "AKIA6KR6UZLLVZYGDEZM",
  secretAccessKey: "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"
});
export default {
  props: ["isEditInquiry"],
  computed: {
    ...mapState("user", ["role"])
  },
  components: {
    SuccessContact
  },
  data() {
    return {
      editInquiryForm: {
        company_name: null,
        country: null,
        title: null,
        company_type: null,
        industry: null,
        product_list: null,
        region : null,
        sales_revenue: null,
        employees: null,
        year_established: null,
        name: null,
        tel: null,
        business_territory: null,
        facebook: null,
        linkedin: null,
        whatsapp: null,
        category: null,
        application: null,
        trade_shows: null,
        logo: null,
        business_card: null,
        attendee_photo: null,
       
      },
      show: false,
      loading: false,
      inStock: true,
      btnLoading: false,
      basisAndPriceVal: [["FOB", null]],
      basisVal: ["FOB", "CIF"],
      qcFile: null,
      coaFile: null,
      logo: null,
      business_card: null,
      attendee_photo: null,
      uplodCoaLoading: false,
      uplodAttendeePhotoLoading: false,
      uplodBusinessCardLoading: false,
      uplodQcLoading: false,
      isEdit: false,
      isView: false,
      countryList: [
        { id: 1, name: "United States" },
        { id: 2, name: "Canada" },
        { id: 45, name: "China" }
      ],
      // inquriy: {}
      showVendor: false,
      companyType: [
        'Mnfr/Producer/Factory',
        'Brand Owner',
        'Contract Mnfr/OEM/ODM/Copacker',
        'Retailer/SuperMarket',
        'Farm',
        'Media/Publisher/Association',
        'Consulting',
        'Formulation/Solution/Innovation',
        'Lab Testing',
        'Service Provider'

      ],
      manufactureType: [
        'Raw Material','Finished Products','Packaging/Printing','Equipment'
      ],
      industry: [
        'Ingredients/Raw Materials',
        'Flavors/Fragrances',
        'Finished Products (Consumer)',
        'Packaging/Label/Printing',
        'Equipment',
        'Regulatory',
        '3rd Party Certifier',
        'QC/QA',
        'Government/Authority',
        'Marketing',
        'Customs Broker',
        '3PL Warehouse Storage/Fulfillment',
        'Freight Forwarder/Logistics',
        'Others'
      ],
      salesRevenue: [
        '< 1M','1m ~ 5M','5M ~ 10M','10M ~ 50M','50M ~ 100M','> 100M',
    ],
      employees: [
        '< 10','10 ~ 50','50 ~ 100','100 ~ 500','500 ~ 1000','> 1000'
      ],
      title : [
        'Procurement','Sales','Marketing','R&D','QC/Lab','Regulatory','Product','Production','Executives','Others'
      ],
      step: 1,
      company_list: {},
      currentTab: 1,
      contactsLoading: false,
      contacting: false,
      page:{},
      pageNum: 1,
      canContact: true

    };
  },
  watch: {
    company_list() {
      this.getData();
    }
  },
  mounted() {
    _country().then(response => {
      const { data } = response;
      this.countryList = data;
    });
  },
  methods: {
    chooseTab(num){
      this.currentTab = num;
    },
    prevPage(){
      this.pageNum--;
      this.getData();
    },
    nextPage(){
      this.pageNum++;
      this.getData();
    },
    getData(){
      this.contactsLoading = true;
      _getSingleCompanyShows({
        id : this.company_list.id,
        page: this.pageNum
      })
        .then(response => {
          if(response.code == 0){
            this.shows = response.data[0].data;
            this.editInquiryForm = response.data[1];
            // this.editInquiryForm.industry =  response.data[1].industry.split(',');
            // this.editInquiryForm.company_type =  response.data[1].company_type.split(',');
            this.page = {'current_page' : response.data[0].current_page,
              'last_page' : response.data[0].last_page};
            this.canContact = response.data[2];
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.contactsLoading = false;
        });
    },
    next(){
      // this.step++;
      if (this.role === "") {
        this.$emit('notLogin');
        return;
      }
      this.contacting = true;
      _directContactTradeShowCompany({
        id : this.company_list.id
      })
        .then(response => {
          if(response.code == 0){

            this.$refs.successContact.show = true;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.contacting = false;
        });

    },
    previous(){
      this.step--;
    },
  
    handleUploadQc(e) {
      if ("" === this.role) {
        this.$refs.popLogin.showLogin = true;
        return;
      }
      const file = e.target.files[0];
      if (file) {
        if (file.size > 10 * 1024 * 1024) {
          this.$Message.error("File size cannot exceed 10MB");
        } else {
          this.qcFile = file;
          this.uploadQc();
        }
      }
    },
    uploadQc() {
      if ("" === this.role) {
        this.$refs.popLogin.showLogin = true;
        return;
      }
      this.uplodQcLoading = true;
      uploader
        .uploadFile(this.qcFile)
        .then(data => {
          this.qcFile = data.location;
        })
        .catch(() => {
          this.$Message.error("upload error");
        })
        .finally(() => {
          this.uplodQcLoading = false;
          // this.$refs.create.validateField("url");
          this.showUploading = true;
        });
    },
    changeBasis(basis) {
      this.editInquiryForm.basis === basis
        ? (this.editInquiryForm.basis = "")
        : (this.editInquiryForm.basis = basis);
    },
    changeHideCompany() {
      this.editInquiryForm.hidden_company =
        this.editInquiryForm.hidden_company === 0 ? 1 : 0;
    },
    bulkUpload() {
      this.$emit("bulkUpload");
    },
    changeType(type) {
      this.editInquiryForm.type === type
        ? (this.editInquiryForm.type = 2)
        : (this.editInquiryForm.type = type);
    },
    deleteUpload(type) {
      if ("qc" == type) {
        this.qcFile = null;
      } else if('attendee_photo' == type) {
        this.attendee_photo = null;
        this.editInquiryForm.attendee_photo = null;
      }else if('business_card' == type) {
        this.business_card = null;
        this.editInquiryForm.business_card = null;
      }else {
        this.logo = null;
        this.editInquiryForm.logo = null;
      }
    },
    handleUpload(e) {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 20 * 1024 * 1024) {
          this.$Message.error("File size cannot exceed 20MB");
        } else {
          this.qcFile = file;
          this.upload();
        }
      }
    },
    handleCoaUpload(e) {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 1 * 1024 * 1024) {
          this.$Message.error("File size cannot exceed 1MB");
        } else {
          this.logo = file;
          this.uploadCoa();
        }
      }
    },
    handleAttendeePhotoUpload(e) {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 1 * 1024 * 1024) {
          this.$Message.error("File size cannot exceed 1MB");
        } else {
          this.attendee_photo = file;
          this.uploadAttendeePhoto();
        }
      }
    },
    handleBusinessCardUpload(e) {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 1 * 1024 * 1024) {
          this.$Message.error("File size cannot exceed 1MB");
        } else {
          this.business_card = file;
          this.uploadBusinessCard();
        }
      }
    },
    upload() {
      this.uplodQcLoading = true;
      uploader
        .uploadFile(this.qcFile)
        .then(data => {
          this.qcFile = data.location;
        })
        .catch(() => {
          this.$Message.error("upload error");
        })
        .finally(() => {
          this.uplodQcLoading = false;
        });
    },
    uploadAttendeePhoto() {
      this.uplodAttendeePhotoLoading = true;
      uploader
        .uploadFile(this.attendee_photo)
        .then(data => {
          this.attendee_photo = data.location;
          this.editInquiryForm.attendee_photo = data.location;
        })
        .catch(() => {
          this.$Message.error("upload error");
        })
        .finally(() => {
          this.uplodAttendeePhotoLoading = false;
        });
    },
    uploadBusinessCard() {
      this.uplodBusinessCardLoading = true;
      uploader
        .uploadFile(this.business_card)
        .then(data => {
          this.business_card = data.location;
          this.editInquiryForm.business_card = data.location;
        })
        .catch(() => {
          this.$Message.error("upload error");
        })
        .finally(() => {
          this.uplodBusinessCardLoading = false;
        });
    },
    uploadCoa() {
      this.uplodCoaLoading = true;
      uploader
        .uploadFile(this.logo)
        .then(data => {
          this.logo = data.location;
          this.editInquiryForm.logo = data.location;
        })
        .catch(() => {
          this.$Message.error("upload error");
        })
        .finally(() => {
          this.uplodCoaLoading = false;
        });
    },
    minusBasis(id) {
      this.$delete(this.editInquiryForm.basis_and_price, id);
    },
    changeStock() {
      this.editInquiryForm.in_stock = this.editInquiryForm.in_stock > 0 ? 0 : 1;
    },
    addBasis() {
      this.editInquiryForm.basis_and_price.push(["FOB", null]);
    },

    save() {
      this.btnLoading = true;
      if (this.role === "") {
        this.btnLoading = false;
        this.$Message.error("Please log in first");
        return;
      }
      this.editInquiryForm.qc = this.qcFile;
      this.editInquiryForm.coa = this.coaFile;
      // this.editInquiryForm.type = 2;
      _AttendTradeShow({ ...this.editInquiryForm })
        .then(() => {
          this.$Message.success("success");
          this.show = false;
          this.editInquiryForm = {};
        })
        .catch(err => {
          this.$Message.error(err.message);
        })
        .finally(() => {
          this.btnLoading = false;
        });
    }
  }
};
</script>
<style lang="less" scoped>
.attend-show-complete {
  .top {
    margin-bottom: 12px;

    .name {
      color: #23262f;
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 30px;
      .bulk {
        font-size: 14px;
        color: #7f8083;
        span {
          cursor: pointer;
          color: #f57f45;
        }
      }
    }
  }

  .content {
    .item {
      display: flex;
      margin-bottom: 15px;
      justify-content: space-between;

      .input {
        .label {
          color: #87898e;
          font-size: 13px;
          /*margin-bottom: 12px;*/
          width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .red-point {
            color: red;
            position: relative;
            top: 4px;
            left: 3px;
            font-size: 18px;
          }
        }
        .label-bold {
          /*font-weight: 500;*/
          /*color: #222;*/
        }

        .plus {
          width: 20px;
          position: relative;
          top: 3px;
          left: 2px;
        }

        .minus {
          width: 20px;
          position: relative;
          top: 3px;
          left: 2px;
        }

        .value {
          width: 240px;
          font-size: 15px;
          font-weight: 500;
          .value-item {
            display: flex;
            /*width: 78px;*/
            margin-top: 8px;
          }
          input {
            border-radius: 12px;
            /*height: 42px;*/
            border: none;
            outline: none;
            width: 240px;
            /*padding-left: 10px;*/
            background: none;
            font-size: 16px;
            font-weight: 500;
          }
          textarea{
            border: none;
            outline: none;
            border-radius: 12px;
            /*height: 90px;*/
            width: 640px;
            /*padding: 10px;*/
            background: none;
            font-size: 16px;
            font-weight: 500;
          }
          select {
            border-radius: 12px;
            /*height: 42px;*/
            border: none;
            outline: none;
            width: 240px;
            padding-left: 10px;
            background: none;
          }

          .basis-price {
            width: 145px !important;
          }

          input:focus {
            border: 2px solid #f57f45;
          }

          .basis {
            display: flex;

            .select {
              width: 70px;

              select {
                height: 42px;
                border: 2px solid #dfdfe6;
                border-right: none;
                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                width: 70px;
                outline: none;
              }
            }
            .basic-value {
              input {
                width: 170px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
              }
            }
          }
        }
        .value-industry{
          width: 740px;
        }
        .value-type {
          display: flex;
          width: 370px;
        }
        .value-hide {
          margin-top: 37px;
        }
        .file-value {
          display: flex;
          .upload-processing {
            margin-top: 10px;
            margin-left: 25px;
          }
          .upload-completed {
            margin-left: 5px;
            svg {
              position: relative;
              top: 3px;
            }
            span {
              margin-left: 5px;
            }
            .view-file{
              img{
                width: 40px;
                height: 40px;
                border-radius: 5px;
              }
            }
            .delete-file{
              position: relative;
              bottom: 3px;
            }
          }
        }
        .basis-value {
          padding-bottom: 10px;
        }

        .value-c {
          input {
            width: 468px;
          }
        }

        .value-stock {
          padding-top: 4px;
        }
      }
    }
  }

  .btn {
    display: flex;
    justify-content: right;
    margin-right: 20px;
    margin-top: 40px;
    margin-bottom: 10px;
    .save-btn {
      background: #3E1DCE;
      border-radius: 10px;
      width: 105px;
      height: 38px;
      color: #fcfcfd;
      font-weight: 500;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .not-contact{
      opacity: 0.6;
      cursor: auto;
    }
    .contacting{
      cursor: auto;
      opacity: 0.8;
    }
    .pre-btn{
      border: 1px solid #3E1DCE;
      border-radius: 10px;
      width: 120px;
      height: 40px;
      color: #3E1DCE;
      font-weight: 500;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
    }
  }
  .success-info{
    border: 1px solid #eee;
    padding: 20px;
    font-size: 15px;
    font-weight: 500;
    width: 350px;
  }
}
.value {
  .value-item {
    display: flex;
    .item-word {
    }
    .item-icon {
    }
  }
}
.vendor-notify {
  span {
    position: relative;
    bottom: 4px;
    left: 5px;
    color: #f57f45;
    font-weight: 600;
  }
}
.vendor {
  background: #f1f1f1;
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
  .title {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin: 13px auto;
  }
  .input-address {
    width: 555px;
  }
  .value {
    input {
      height: 39px;
    }
  }
}

.vendor-notify-extra {
  margin-top: 10px;
}
.vendor {
  .extra-item .value-c {
    input {
      width: 450px !important;
    }
  }
  .input-address {
    width: 460px !important;
  }
  .value input {
    width: 230px !important;
  }
}
  select option{
    
  }
textarea:focus {
  border: 2px solid #f57f45;
}
  .file-value span{
    img {
      width: 40px;
      height: 40px;
    }
  }
  .tabs{
    display: flex;
    border: 1px solid #e0e0e0;
    border-radius: 40px;
    width: 650px;
    justify-content: center;
    margin-bottom: 20px;
    text-align: center;
    align-items: center;
    .tab-item{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 220px;
      height: 36px;
      font-weight: 500;
      cursor: pointer;
    }
    .tab-item-active{
      border-radius: 18px;
      padding: 8px 15px;
      background: #efeeea;
      color: #777;
      height: 35px;
      font-weight: 500;
    }
  }
.content-item{
  display: flex;
  border-bottom: 1px solid #dadada;;
  padding-bottom: 7px;
  padding-top: 7px;
  .left{
    width: 50%;
    .name{
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 15px;
      line-height: 17px;
      /* identical to box height */


      color: #222222;
      margin-bottom: 4px;
      /*height: 17px;*/
      span{
        margin-right: 10px;
        img{
          width: 40px;
          border-radius: 50%;
        }
      }

    }
    .email{
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      /* identical to box height */


      color: #222222;
      max-width: 90%;
      word-break: break-all;
      cursor: pointer;
    }
  }
  .middle{
    width: 30%;
    flex-wrap: wrap;
    /*align-items: center;*/
    display: flex;
    align-items: center;
    .middle-item{
      background: #D3D9DE;
      border-radius: 6px;
      padding: 2px 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 7px;
      margin-top: 3px;
      min-height: 30px;
      font-size: 14px;
      max-width: 80%;
    }
  }
  .right{
    width: 20%;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    .right-item{
      cursor: pointer;
      img{
        width: 23px;
        height: auto;
      }
      .not-has-icon{
        cursor: auto;
        opacity: 0.3;
      }

      margin-right: 13px;
    }
    .right-item-phone{
      cursor: pointer;
    }

  }
}
.contacts-page{
  display: flex;
  justify-content: space-between;
  width: 50px;
  margin: 30px auto 0 auto;
  div{
    cursor: pointer;
    img{
      width: 10px;
      height: 17px;
    }
  }
}
  .contacts-loading{
    display: flex;
    justify-content: center;
    img{
      margin-top: 10px;
      width: 40px;
      height: 40px;
    }
  }
textarea.profile-textarea{
  width: 460px !important;
}
.item-top-info{
  display: flex;
  justify-content: flex-start !important;
  margin-bottom: 10px;
  .item-logo{
    margin-right: 10px;
    img{
      width: 70px;
      height: 70px;
    }
  }
  .item-company_name{
    margin-top: 12px;
    .company-name{
      font-size: 18px;
      font-weight: 600;
    }
  }

}
</style>

<style lang="less">
.attend-show-complete {
  .ivu-modal {
    width: 760px !important;

    .ivu-modal-content {
      padding: 20px 20px;
    }
  }
  .vs__deselect{
    display: none;
  }
}
</style>
