<template>
  <div>
    <Modal class="attend-show-pop" footer-hide v-model="show">
      <div class="pop-content">
        <div class="icon">
          <img src="@/assets/payment-success.png" alt=""/>
        </div>
        <div class="success-info">
          We have successfully sent an email to the company. Please wait for them to contact you.
        </div>
      </div>


      <div class="btn">
        <div class="save-btn search-button" @click="next" >
          I got it
        </div>

      </div>
    </Modal>
  </div>
</template>

<script>

export default {
  props: ["isEditInquiry"],
  computed: {
  },
  data() {
    return {
      show: false


    };
  },
  watch: {

  },
  mounted() {

  },
  methods: {
    next(){
      this.show = false;
    }
  }
};
</script>
<style lang="less" scoped>
  .pop-content{
    display: flex;
    .icon{
      margin-right: 20px;
      img{
        width: 40px;
        height: 40px;
      }
    }
    .success-info{
      font-size: 15px;
      font-weight: 500;
    }

  }
  .btn{
    display: flex;
    justify-content: right;
    .save-btn{
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgb(209 213 219);;
      border-radius: 10px;
      padding: 6px 20px;
      font-size: 15px;
      cursor: pointer;
      font-weight: 500;
    }
  }
</style>

<style lang="less">
.attend-show-pop {
  .ivu-modal {
    width: 420px !important;

    .ivu-modal-content {
      padding: 20px 20px 5px 20px;
    }
  }

}
</style>
